/********************* CUSTOM  *********************/
/********************* Font family  ****************/
body {
    font-family: 'Ubuntu', sans-serif;
}
#main-container {
    margin-top: 20px;
}
/********************* Header menu  *********************/
#admin-page {
  padding-top: 0px; 
}

#page {
  padding-top: 0px; 
}

.navbar {
    /*height: 80px;*/
    background-color: #e6e7e8;
}

.logo-image {
    width: 150px;
    height: 59px;
}

#logo-image {
    padding: 11px 15px;
}

.header-menu {
    font-size: 13px;
    line-height: 16px;
    color: #000;
    padding-top: 10px;
}

.header-menu > li > a {
    color: #000;
}

.header-menu > li > a:hover {
    color: #333;
}

/*Header Search*/
.hero-search-box {
    margin-top: 23px;
    margin-left: 20px;
    /*background: #333;
    border-radius: 50px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    position: relative;*/
}
.form__search-container {
    height: 40px;
    width: 452px;
    position: absolute;
    background-color: #fff;
    border-radius: 50px;
    margin-left: 250px;
    margin-top: -32px;
    border: 1px solid;
    padding-left: 20px;
    padding-right: 10px;
    border-color: #d0d2d3;
}

.search-filter {
    border-radius: 50px;
    height: 27px;
    background-color: #e6e7e8;
    color: #000;
    font-size: 13px;
    border-color: #58595b;
    padding: 3px 9px;
}

.search-filter .focus {
    border: none;
    outline: none;
}

.search-filter--sola, 
.search-filter--novo {
    width: 66px;
}

.search-filter--antikvariat {
    width: 103px;
}

.search-filter:hover {
    background-color: #d0d2d3;
}
.search-filter--antikvariat:active,
.search-filter--antikvariat.active {
    background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #9c6aae , #7f3f97); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #9c6aae, #7f3f97); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #9c6aae, #7f3f97); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #9c6aae , #7f3f97); /* Standard syntax */
  color: #fff;
  border: none;
    outline: none;
}
.search-filter--novo:active,
.search-filter--novo.active{
    background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #da695c , #c03b2b); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #da695c, #c03b2b); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #da695c, #c03b2b); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #da695c , #c03b2b); /* Standard syntax */
  color: #fff;
  border: none;
    outline: none;
}
.search-filter--sola:active,
.search-filter--sola.active {
    background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #50ba70 , #1e8348); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #50ba70, #1e8348); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #50ba70, #1e8348); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #50ba70 , #1e8348); /* Standard syntax */
  color: #fff;
  border: none;
    outline: none;
}

.filter-container {
    display: inline-block;
}

.hero_form__submit {
    float: right;
    border-radius: 50px;
    margin-top: 5px;
    height: 27px;
    width: 59px;
    background-color: #00a551;
    color: #fff;
    font-size: 13px;
    border: none;
}
.hero_form__input--search {
    width: 80%;
    border: none;
    margin-top: 10px;
}
.hero_form__input--search:focus {
    border: none;
    outline: none;
}
.hero_form__submit:hover {
    background-color: #50ba70;
}

.btn.focus {
    outline: none !important;
}

.header-menu-antika {
    height: 80px;
}

/****************User Profile  *********************/
.user-row {
    margin-bottom: 14px;
}

.user-row:last-child {
    margin-bottom: 0;
}

.dropdown-user {
    margin: 13px 0;
    padding: 5px;
    height: 100%;
}

.dropdown-user:hover {
    cursor: pointer;
}

.table-user-information > tbody > tr {
    border-top: 1px solid rgb(221, 221, 221);
}

.table-user-information > tbody > tr:first-child {
    border-top: 0;
}


.table-user-information > tbody > tr > td {
    border-top: 0;
}
.toppad
{margin-top:20px;
}

/***************** Posts grid *******************************/
.section-novice-container {
   
}

.main-novica-container {
    border: 1px solid #f8f8f8;
    padding: 27px;
    width: 700px;
    display: inline-block;
}

.main-novica-container:hover {
    border: 1px solid #ddd;
    background-color: #fafafa;
    cursor: pointer;
}

.main-novica-container:hover .novica-more {
    display: inline-block;
     float: right;
    padding-right: 27px;
}

.main-novica-image-container {
    display: inline-block;
}

.main-novica-image,
.second-novica-image {
    width: 297px;
}

.main-novica-data-container {
    position: absolute;
    width: 350px;
    margin-left: 27px;
    display: inline-block;
    padding-right: 27px;
}

.main-novica-data-datum,
.second-novica-data-datum {
    font-size: 12px;
    color: #58595b;
}

.second-novica-data-datum {
    margin-top: 5px;
}

.main-novica-data-header {
    font-weight: bold;
    font-size: 24px;
}

.second-novica-container {
    border: 1px solid #f8f8f8;
    padding: 27px;
    display: inline-block;
    float: right;
}

.second-novica-container:hover {
    border: 1px solid #ddd;
    background-color: #fafafa;
    cursor: pointer;
}

.second-novica-container:hover .novica-more-second {
    display: inline-block;
    margin-left: 260px;
}

.novica-more,
.novica-more-second {
    display: none;
    color: #404041;
}

.second-novica-data-header {
    font-size: 18px;
    color: #000;
}

.novica-more-second {
    position: absolute;
}

.bottom-novice {
    margin-top: 20px;
}

.bottom-novica-container {
    width: 33%;
    padding-left: 27px;
    border: 1px solid #f8f8f8;
    display: inline-block;
}

.bottom-novica-container:hover {
    border: 1px solid #ddd;
    background-color: #fafafa;
    cursor: pointer;
}

.bottom-novica-data-datum {
    font-size: 12px;
    color: #58595b;
}

.bottom-novica-data-header {
    font-size: 18px;
    color: #000;
}

.novica-more-bottom {
    float: right;
    display: block;
    color: #404041;
    visibility: hidden;
}

.bottom-novica-container:hover .novica-more-bottom {
    visibility: visible;
}

/*post*/

.right-novice-container {
    margin-top: 20px;
}

.right-novica-container {
    width: 100%;
    border: 1px solid #f8f8f8;
    display: inline-block;
    height: 100%;
    padding: 27px;
}

.right-novica-container:hover {
    border: 1px solid #ddd;
    background-color: #fafafa;
    cursor: pointer;
}

.right-novica-data-datum {
    font-size: 12px;
    color: #58595b;
}

.right-novica-data-header {
    font-size: 18px;
    color: #000;
}

.novica-more-right {
    float: right;
    display: block;
    color: #404041;
    visibility: hidden;
}

.right-novica-container:hover .novica-more-right {
    visibility: visible;
}

/********************* Books grid  *********************/
.antika-book {
    width: 192px;
    min-height: 290px;
}

.antika-book .standard {
    background-color: #333;
}

/*types*/
.standard {
    background-color: #50ba70;
}
.SA {
    background-color: #7f3f97;
}
/*endtypes*/

.antika-thumbnail {
    border: 1px solid #f8f8f8;
    background-color: #f8f8f8;
    cursor: pointer;
}

.thumbnail-cena {
    font-size: 18px;
    color: #00a551;
    display: inline-block;
}

.thumbnail-avtor {
    font-size: 12px;
    color: #58595b;
}

.thumbnail-naslov {
    font-size: 18px;
    color: #000;
}

.antika-thumbnail-caption {
    margin-left: auto;
    display: block;
    margin-right: auto;
    width: 192px;
    padding-left: 0;
}

.thumbnail-more {
    display: none;
    float: right;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 13px;
    font-weight: bold;
    color: #404041;
}

.thumbnail-more:hover {
    color: #404041;
}

.thumbnail .caption {
    padding-left: 0;
}

.thumbnail img {
    margin-top: -24px;
}

.antika-thumbnail:hover {
    border: 1px solid #ddd;
    background-color: #fafafa;
}

.antika-thumbnail:hover ~ .antika-book {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.antika-thumbnail:hover .thumbnail-more {
    display: inline-block;
}

.antika-thumbnail:hover .books-kosarica{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.antika-thumbnail:hover .books-kosarica-a {
    display: block;    
}

.books-kosarica {
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
}

.books-kosarica-a {
    position: absolute;
    margin-left: 200px;
    display: none;
}

.books-kosarica:hover {
    box-shadow: 0 4px 7px rgba(0,0,0,0.23), 0 4px 7px rgba(0,0,0,0.30) !important;
}

.item {
    margin-bottom: 20px;
}

.index-section-header {
    margin-bottom: 50px;
    padding-left: 35px;
}

.fake-image {
    width: 190px;
    height: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;
}

.fake-image h3 {
    position: absolute;
    top: 80px;
    color: white;
    text-align: center;
    font-size: 14px;
    left: 25%;
    width: 150px;
}


/*Book dialog*/

#myModal-Book {
    top: 10%;
}

#myModal-Book .modal-dialog {
    /*width:1350px;*/
    width:1150px;
    height: 520px;
}

.dialog-image {
    width: 275px;
    height: 412px;
}

.modal-content {
    background-color: #fafafa;
}

.dialog-image-container {
    /*width: 347px;*/
    display: inline-block;
}

.modal-body {
    padding: 36px;
}

.fake-image-dialog {
    width: 275px;
    height: 412px;
}

.fake-image-dialog h3 {
    position: absolute;
    top: 160px;
    color: white;
    text-align: center;
    font-size: 24px;
    left: 4%;
    width: 240px;
}

.dialog-data-container {
    position: absolute;
    display: inline-block;
    margin-left: 36px;
    padding-right: 36px;
}

.dialog-author {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    line-height: 1;
    margin-top: -3px;
}

.dialog-title {
    font-size: 32px;
    margin-top: 5px;
}

.dialog-table-container {
    width: 400px;
    margin-top: 60px;
    font-size: 16px;
    font-weight: 200;
    display: inline-block;
}

.dialog-table th {
    width: 100px;
    color: #757575;
    border: none;
    padding: 4px !important;
}
.table>thead>tr>th, .table>tbody>tr>th, .table>tfoot>tr>th, .table>thead>tr>td, .table>tbody>tr>td, .table>tfoot>tr>td {
    border: none;
    padding: 4px;
}

.dialog-description-container {
    display: inline-block;
    position: absolute;
    margin-top: 60px;
}
    
#close-button-modal {
    margin-right: -10px;
    margin-top: -20px;
}

.dialog-footer-container {
    display: inline-block;
    position: absolute;
    margin-top: 330px;
    margin-left: 36px;
    width: 750px;
}

.dialog-buttons-container {
    float: right;
}

.dialog-oznake-container {
    display: inline-block;
    margin-top: 10px;
}

.dialog-kosarica-button {
    border-radius: 20px;
    background-color: #00a551;
    color: white;
    height: 42px;
    width: 151px;
    font-size: 18px;
}

.dialog-kosarica-button img {
    margin-bottom: 3px;
}

.dialog-kosarica-button:hover {
    color: white;
    background-color: #50ba70;
}

.kosarica-hidden {
    display: none;
    visibility: hidden;
}

/*CART*/
.cart-footer {
    margin: 0!important;
}

.product-details h3 {
    margin: 0 !important;
}

.cart-image-big {
    max-width: 20px;
}

.table-cart {
    text-align: center;
}

.table-cart>tbody>tr>td {
    vertical-align: middle;
}

.cart-table-header {
    text-align: center;
}

/*Contact*/

.contact-header {
    background-color: #F5F5F5;
    color: #00a551;
    height: 90px;
    font-size: 27px;
    padding: 10px;
}

.contact-map {
    min-width: 300px;
    min-height: 300px;
    width: 100%;
    height: 100%;
}

/*Post gallery*/

.postgallery-image {
    max-height: 600px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}